<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons class="back" slot="start">
        <ion-back-button default-href="/home" text="" v-if="sharedState.showBackButton"></ion-back-button>
        <ion-button @click="closeSearch" v-else-if="sharedState.isSearching">
          <ion-icon slot="icon-only" :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title><img src="/img/oscER-logo-header.svg"></ion-title>
      <ion-buttons class="audio" slot="end">
        <AudioPlayer />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon } from '@ionic/vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import { store } from '@/store';
import { arrowBack } from 'ionicons/icons';

export default  {
  name: 'AppHeader',
  components: { AudioPlayer, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon },
  setup() {
    return {
      arrowBack,
    }
  },
  data() {
    return {
      sharedState: store.state,
    }
  },
  methods: {
    closeSearch() {
      store.updateItem('isSearching', false);
    }
  },
  mounted() {
    this.$el.querySelector('ion-back-button')?.addEventListener('click', () => this.closeSearch());
  }
}
</script>

<style scoped>
  img {
    margin: 0 auto;
    max-width: 108px;
    max-height: 36px;
    display: block;
  }

  .back {
    position: absolute;
    left: 0.25em;
  }

  .audio {
    position: absolute;
    right: 0.25em;
  }
</style>